const mapClientIdByHostname: Record<string, string> = {
  'demo-app.revers.io': 'demo-control.revers.io',
  'qualif-demo-app.revers.io': 'demo-control.revers.io',
  localhost: 'demo-control.revers.io',
};

const AuthConfiguration = {
  authority: 'https://demo-login.revers.io',
  clientId: 'demo-control.revers.io',
  scope: 'openid email api',
};

const { hostname } = window.location;

AuthConfiguration.clientId =
  mapClientIdByHostname[hostname] || window.location.hostname;

export default AuthConfiguration;
